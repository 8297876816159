<template>
  <div class="w-100 mb-4">
    <Header />
    <Form @saved="loadData" />
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Form from "../components/Form.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: { Header, Form },
  computed: {
    ...mapState("source", ["source"]),
  },
  methods: {
    ...mapActions("source", ["ActionNew"]),
    loadData(id) {
      this.$router.push({ name: "source_edit", params: { id: id } });
    }
  },
  async created() {
    this.ActionNew();
  },
};
</script>

<style>
</style>
